/* src/App.css */
.App {
  text-align: center;
  height: 100vh;
  /*background-image: url('/public/black.png');
  background-size: cover;
  background-position: center;*/
  background-color: rgb(254, 255, 237);
  margin: 0;
  padding: 0;
}
