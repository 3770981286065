/* src/Home.css */

@font-face {
  font-family: "IS";
  src: url("./assets/fonts/InstrumentSerif-Regular.ttf") format("truetype"); /* Update path to the correct font file */
  
}

@font-face {
  font-family: "ISI";
  src: url("./assets/fonts/InstrumentSerif-Italic.ttf") format("truetype"); /* Update path to the correct font file */
  
}

html {
  scroll-behavior: smooth; /* Ensure smooth scrolling */
}

body {
  background-color: rgb(
    254,
    255,
    237
  ); /* Set the body background color to #3C2A24 */

  margin: 0; /* Remove default body margin */
  padding: 0; /* Remove default body padding */
  color: #3C2A24;

  /*font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;*/
  font-family: "Helvetica Neue";
}

:root {
  --cursor: default;
}

body {
  cursor: var(--cursor);
}

a,
button,
.circle, .nav-link,
[role="button"] {
  cursor: pointer;
}

a {
  color: white; /* Set link color to white */
  text-decoration: none; /* Remove underline from links */
}

/* src/Home.css */
/* Center .central-content within .app-header */
.app-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(254, 255, 237);
  position: relative;
  padding: 0;
}
/* Home.css */
/* Home.css */
.cursor-trail-square {
  
  background-color: rgba(150, 0, 0, 1);
  top: 15vh;
  right: 14vw;
  position: absolute;
  z-index: 99;
  cursor: pointer;
  filter: blur(10px);
  opacity: 0;
  /*border-radius: 50%;*/
  transition: opacity 0.5s ease-out;
  animation: unblur 0.5s ease-out forwards;
  transition: transform 0.1s ease, box-shadow 0.1s ease; /* Smooth transition for movement and shadow */
 box-shadow:  -5px 5px 1px rgba(0, 0, 0, 0.2); /* Default shadow */
}

/* Add an active state for the 'button press' effect */
.cursor-trail-square:hover {
 transform: translateY(5px); /* Moves the square down slightly */
 box-shadow: none;
}



.header-content {
  display: flex;
 
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  filter: blur(10px);
  opacity: 0;
  transition: opacity 0.5s ease-out;
  animation: unblur 0.5s ease-out forwards;
}
@keyframes unblur {
  0% {
    filter: blur(10px);
    opacity: 0;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}

/* Center .site-title horizontally */
.central-content {
  position: relative;
  top: -40px; /* Move content up by 20px */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*height: 100%;*/
  
  width: 100%;
  /* for block set up*/
  max-width: 800px; /* Adjust to your desired max width */
  margin: 0 auto; /* Center content */
  text-align: center; /* Center align text */
}

/* Ensure .site-title stays centered */
.site-title {
  color: #3C2A24;
  font-family:"Times New Roman", Times, serif;
  font-size: 9.5rem; /*8.5rem, 21vw*/
  font-weight: 400;
  letter-spacing: -0.075em;
  line-height: 1;

  white-space: nowrap;
  margin-bottom: 0.1rem;
  /*text-align: center;*/
  /*
  position: absolute;
  top: -2vh;
  left: 20px;
  margin: 0; */ /* Removes extra margins */
}





.site-title span {
  transition: all 0.9s ease;
}

/*ABOUT & WORK button*/
.nav-links {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; /* Make sure the nav-links span full width */
  max-width: 560px; /* Adjust the width for equal spacing */
  margin: 1rem 0;
  margin-bottom: 1rem;
  color: #3C2A24;
  font-size: 1.1em;
  font-weight: 400;
  letter-spacing: -0.05rem;
  z-index: 999;
  
}

.nav-link {
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit; /* Use the same color as text */
  color: rgba(150, 0, 0, 1);
  


 
}



.nav-link:hover {
  text-decoration: underline;
}

.about-section {
  width: 100%;
  max-width: 560px; /* Match max-width to site-title */
  margin-top: 0rem;
}

.about-text {
  font-size: 1.38rem;
  line-height: 1.6;
  font-family: 'Times New Roman';
  letter-spacing: -0.07rem;
  line-height: 1.2;
  text-align: justify;
}







/* Works section */
.works-section {
  position: relative;
  /*background-color: rgb(0, 0, 0);*/
  background-color: rgb(254, 255, 237);
  text-align: center;
  color:#3C2A24;
  /*font-family: 'Arial', sans-serif;*/
  font-family: "Helvetica Neue";

  /*margin-top: 5vh;*/
  /*min-height: 100vh;*/
  /*overflow: hidden;*/
  padding: 0px 0; /* Add some padding to the top and bottom */
}

/* Work card styling */
.work-card {
  display: inline-flex;
  flex-direction: column;
  background-color: rgb(254, 255, 237);
  color: #3C2A24;
  letter-spacing: -0.03rem;
  font-weight: 100;
  text-align: left;
  filter: none;
  width: 47vw; /* Set a fixed width */
  margin: 0 auto; /* Center the card if it's less than full width */
  -webkit-transform: translateY(0);
  transition: transform 0.3s ease;
}

/* Adjust the size and position of the image */
.work-image-wrapper {
  transition: filter 0.3s;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.work-image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border: 2px solid transparent;
  object-fit: scale-down;
  transition: box-shadow 0.3s ease, opacity 0.3s ease, filter 0.3s ease;
  opacity: 1;
  /*filter: brightness(60%);*/
}

.work-image:hover {
  box-shadow: 0 0 0 2px rgb(150, 0, 0);
  opacity: 0.8;
  
  /*filter: brightness(110%);*/
}

/* Adjust the size of the text */
.work-info {
  display: flex; /* Set up flex display */
  justify-content: space-between; /* Space items apart */
  align-items: left; /* Align items vertically centered */
  flex-direction: column;
  -webkit-transform: translateZ(0);
  margin-bottom: 25px;
}
.work-title-category {
  display: flex;
  justify-content: space-between; /* Space between title and category */
  width: 100%; /* Ensure the row stretches across the container */
  align-items: center; /* Align items vertically centered */
  margin-bottom: 8px; /* Add spacing below the row */
}

.work-title {
  font-size: 1.2rem;
  font-family: "Helvetica";
  letter-spacing: -0.05rem;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0vh;
}


.work-category {
  font-family: 'Helvetica';
  font-size: 0.9rem; /* Adjust font size as needed */
  letter-spacing: -0.01em;
  /*font-style: italic;*/
  color: rgba(150, 0, 0, 1);
  font-weight: 300;
  text-align: right; /* Right-align the description text */
  margin-top: 10px;
  margin-bottom: 0vh;
  flex-shrink: 0; /* Prevent description from shrinking */
  background-color: rgba(226, 215, 215, 0); /* Background color for the tag */
  padding: 2px 8px; /* Adjust padding for the oval shape */
  border-radius: 20px; /* Create the oval shape */
  border: solid 1px rgba(150, 0, 0, 1);
  
  display: inline-block; /* Ensure the tag wraps tightly around the text */
}

.work-description {
  font-family: 'Helvetica';
  font-size: 0.95rem; /* Adjust font size as needed */
  letter-spacing: -0.02em;
  line-height: 1.10;
  color: rgb(128, 128, 128);
  font-weight: 400;
  text-align: left; /* Align the description to the left */
  margin-top: 0px; /* Add spacing above the description */
}


.works-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1vw;
  padding: 2vw;
  position: relative;
  z-index: 1; /* Ensure work cards are above filter circles */
}
@media (max-width: 600px) {

  .cursor-trail-square {
    display: none;
  }
}
@media (max-width: 600px) {
  /* Adjust header content for mobile */
  

  
  .central-content {
    
     /* Move content up by 20px */
    
    width: 85%;
  }

  .site-title {
    font-size: 22.6vw;
  }
  .nav-links { 

    margin-bottom: 0.1rem;
  }
  .work-description {
    
    font-size: 1rem;
  }

  

 
}

@media (max-width: 768px) {
  .app-header {
    position: relative;
    height: 100vh;
    overflow: hidden;
    
  }

  /* Work section styles remain the same */

  .work-info {
    text-align: left;
  }

  /* Works section styles */
  .works-section {
    padding: 0 4vw; /* Reduce padding for mobile */
    margin-top: 0px; /* Adjust margin */
  }
  .works-container {
    display: flex;

    flex-wrap: wrap;
    justify-content: center;
    gap: 5vh;
    padding: 3vw;
    position: relative;
    z-index: 1; /* Ensure work cards are above filter circles */
  }

  .work-card {
    margin: 0; /* Reduce margin for mobile */
    width: 92vw; /* Full width for mobile */
    max-width: 100%; /* Ensure no max-width restriction */
  }

  .work-image-wrapper {
    width: 92vw;
  }

  .work-image {
    opacity: 1;
  }
  .work-title  {
    font-size: 1em; /* Adjust font size */
  }

  .work-category {
    font-size: 0.7em; /* Adjust font size */
  }
  .work-description {
    font-size: 0.85em;
  }

}