/* src/EclipsePage.css */
.fragrance-ai-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: black;
    color: white;
    font-family: 'Inter', sans-serif;
    padding-top: 0.5rem; 
  }
  
  .figma-embed-container {
    position: relative;
    width: 60%;
    height: 80vh;
    border: 2px solid black;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 2rem;
    margin: 0 auto 2rem auto;
  }
  
  .figma-embed-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .fragrance-ai-content {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .fragrance-ai-title {
    font-size: 8vw;
    margin-top: 0; /* Remove top margin */
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .fragrance-ai-description {
    font-size: 1.2em;
    line-height: 1.6;
    text-align: justify;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .figma-embed-container {
      height: 80vh; /* Slightly smaller on mobile devices */
    }
  
    .fragrance-ai-title {
      font-size: 2em;
    }
  
    .fragrance-ai-description {
      font-size: 1em;
    }
  }