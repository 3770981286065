/* Footer css*/
  
.site-footer {
    background-color: transparent;
    /*color: #333;*/
    font-family: 'Helvetica Neue';
    color: #3C2A24;
    margin-top: 0px;
    z-index: 1;
    padding-bottom: 40px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 100vw;
    font-family: 'Helvetica Neue';
    margin: 0 auto;
  }
  
  .footer-section {
    margin-bottom: 5px;
  }

  
  .footer-section h3 {
    
    color: rgba(150, 0, 0, 1);
  font-size: 1.0em;
  font-weight: 400;
  letter-spacing: -0.05rem;
    margin-bottom: 10px;
  }

  .footer-section p{
    font-size: 0.9rem;
  }
  
  .social-links, .footer-nav {
    list-style-type: none;
    
    font-size: 0.9rem;
    font-weight: 400;
    padding: 0;
  }
  
  .social-links li, .footer-nav li {
    margin-bottom: 0px;
  }

  .social-links li::before, .footer-nav li::before {
    content: "";
    position: absolute;
    
    transform: translateY(-50%);
  }
  
  .social-links a, .footer-nav a {
    color: #3C2A24;
    text-decoration: none;
    list-style-type: none;
  }
  
  .social-links a:hover, .footer-nav a:hover {
    text-decoration: underline;
    color: rgba(150, 0, 0, 1);
  }
  .footer-section .email-link {
    color: #3C2A24;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease;
  }
  
  .footer-section .email-link:hover {
    color: rgba(150, 0, 0, 1);
    text-decoration: underline;
    
  }
  .footer-bottom {
    font-family: 'Helvetica Neue';
    
  }
  
  .footer-name {
    font-family: "Times New Roman", Times, serif;
    font-weight: 400;
  letter-spacing: -0.07em;
    font-size: 7vw;
    color: #3C2A24;
    text-transform: none;
    
    margin: 0;
  }
  
  .footer-copyright {
    font-family: 'Helvetica Neue';
    font-size: 0.9em;
    color: #3C2A24;
    
  }

  @media (max-width: 768px) {
    .footer-name {
        font-size: 10vw;
        letter-spacing: -0.07em;
      }
  }