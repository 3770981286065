.about-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.about-page-header {
  position: fixed;
  top: 10px;
  left: 20px;
}

.about-page-link {
  color: #3C2A24;
  font-family: "Times New Roman", Times, serif;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: -0.07em;
  text-decoration: none;
}

.about-page-content {
  max-width: 45vw;
  width: 100%;
  text-align: center;
  margin-top: 60px;
}

.about-page-title {
  color: #3C2A24;
  font-size: 1.2vw;
  font-weight: 400;
  letter-spacing: -0.05rem;
  font-family: "Helvetica Neue";
  margin-top: 5px;
  margin-bottom: 80px;
}

.about-page-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
  color: #3C2A24;
  font-family: "Times New Roman", Times, serif;
  text-align: left;
  font-weight: 500;
  letter-spacing: -0.09em;
  /* Set initial blur to elements for smooth effect */
  filter: blur(10px);
}

.about-page-image {
  width: 100%;
  height: auto;
  margin-bottom: -10px;
}

.about-page-text {
  font-size: 1.5rem;
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: -0.06em;
  margin: 10px 0;
}

.about-page-caption {
  text-align: right;
  font-size: 1rem;
  letter-spacing: -0.07em;
  margin-bottom: 0px;
  color: gray;
  font-style: italic;
  align-self: flex-end; /* Ensures the caption aligns to the right side */
}

.about-page-image,
.about-page-text,
.about-page-caption {
  transition: opacity 0.6s ease, transform 0.6s ease,filter 0.8s ease;;
}

@media (max-width: 768px) {
 /* Shun Ueno */
   .about-page-link {
    font-size: 8vw;
    
  }
  .about-page-header {
    position: absolute;
  }
  .about-page-content {
    max-width: 80vw;
    
    text-align: center;
    margin-top: 60px;
  }

}