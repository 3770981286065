/* src/VNPage.css */
@font-face {
  font-family: "ISI";
  src: url("./assets/fonts/InstrumentSerif-Italic.ttf") format("truetype"); /* Update path to the correct font file */
  
}
@font-face {
  font-family: "IS";
  src: url("./assets/fonts/InstrumentSerif-Regular.ttf") format("truetype"); /* Update path to the correct font file */
  
}

.vn-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(254, 255, 237);
  color: #3C2A24;
  font-family: "Helvetica Neue";
  letter-spacing: -0.02rem;
  padding-top: 0.5rem;
  
  background-image: none;
}

.vn-image-container {
  width: 90%;

  margin: 0 auto 2rem auto;

  border-radius: 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vn-image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
}

.vn-content {
  padding: 2rem;
  width: 47%;
  max-width: 47%;

  margin: 0 auto;
}

.vn-title {
  
  font-size: 8vw;
  font-family: 'IS','Times New Roman', Times, serif;
  font-weight: 400;
  letter-spacing: -0.04em;
  margin-top: 0; /* Remove top margin */
  margin-bottom: 0.5rem;
  text-align: center;
}

.vn-description {
  font-size: 1.2em;
  line-height: 1.6;
  text-align: left;
  color: #3C2A24;
}

.vn-description a {
  color: #3C2A24;
  text-decoration: underline; /* Optional, removes the underline */
}


/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .vn-content {
    padding: 2rem;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  .vn-title {
    font-size: 12vw;
  }

  .vn-description {
    font-size: 4vw;
  }

  .vn-image-container {
    width: 80vw;
  }
}
