/* src/EclipsePage.css */
@font-face {
  font-family: "ISI";
  src: url("./assets/fonts/InstrumentSerif-Italic.ttf") format("truetype"); /* Update path to the correct font file */
  
}
@font-face {
  font-family: "IS";
  src: url("./assets/fonts/InstrumentSerif-Regular.ttf") format("truetype"); /* Update path to the correct font file */
  
}
.movie-game-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
  background-color: rgb(254, 255, 237);
  color: #3C2A24;
  font-family: "Helvetica Neue";
  letter-spacing: -0.03rem;
  padding-top: 0.5rem;
  position: relative; /* Add this line */
  padding-bottom: 0px;
}
.movie-image-container {
  width: 90%;
  margin: 0 auto 2rem auto;
  border-radius: 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Add this to position the button relative to the container */
}

/* Ensure the image fits properly */
.movie-image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
}
.play-button {
  position: absolute;
  top: 90%; /* Position vertically centered */
  left: 50%; /* Position horizontally centered */
  transform: translate(-50%, -50%); /* Adjust for precise centering */
  z-index: 10; /* Ensure the button is above the image */

  font-family: "Helvetica";
   /* Optional: semi-transparent background */
   background-color: rgba(255, 255, 255, 1);
  color: black; /* Button text color */
  padding: 10px 20px; /* Button padding */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
}

.play-button:hover {
   /* Lighter background on hover */
  background-color: rgba(239, 239, 239, 1);
  color: black; /* Darker text on hover */
}

.play-button2 {
  position: absolute;
  top: 130%; /* Position vertically centered */
  left: 50%; /* Position horizontally centered */
  transform: translate(-50%, -50%); /* Adjust for precise centering */
  z-index: 10; /* Ensure the button is above the image */

  font-family: "Helvetica";
   /* Optional: semi-transparent background */
   background-color: rgba(255, 255, 255, 1);
  color: black; /* Button text color */
  padding: 10px 20px; /* Button padding */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
}

.play-button2:hover {
   /* Lighter background on hover */
  background-color: rgba(239, 239, 239, 1);
  color: black; /* Darker text on hover */
}


.project-overview-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* Header above the row layout */
  align-items: flex-start; /* Ensure content aligns to the left */
 
}



.project-overview-content {
  display: flex;
  flex-direction: row; /* Row layout for info and summary */
  justify-content: space-between; /* Align left and right edges */
  align-items: flex-start; /* Align content vertically at the top */
  width: 100%; /* Ensure full width usage */
  gap: 90px; /* Adjust spacing between the two sections */
}

.project-info-container {
  margin-top: 45px;
  flex: 0 0 50%; /* Adjust width of the info container */
  max-width: 50%; /* Prevent it from exceeding 40% */
  text-align: left; /* Align text inside to the left */
}

.project-summary {
  margin-top: 30px;
  flex: 0 0 40%; /* Adjust width of the summary container */
  max-width: 40%; /* Prevent it from exceeding 55% */
  text-align: left; /* Align text inside to the left */
}

.project-info .project-detail {
  margin-bottom: 8px; /* Space between details */
  line-height: 1.6; /* Improve readability */
}

.video-container{
  margin-top: 30px;
  margin-bottom: 80px;
}



@media (max-width: 768px) {
  .project-overview-content {
    flex-direction: column; /* Stack info and summary vertically */
    align-items: flex-start; /* Align everything to the left */
    gap: 0px; /* Add spacing between stacked items */
  }

  .project-info-container,
  .project-summary {
    flex: 1 1 100%; /* Allow full width for both sections */
    max-width: 100%;
  }
  .project-summary{
    margin-top: -30px;
  }
}

.starting-parent{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.starting-point-container {
  display: flex;
  flex-direction: row;
  gap: 60px; /* Space between image and text */
  align-items: center; /* Vertically center content within the container */
}

.starting-point-graph {
  flex: 0.7; /* Adjusts the width of the graph section */
}

.starting-point-text {
  flex: 0.3; /* Adjusts the width of the text section */
  display: flex;
  align-items: center; /* Vertically center the text inside this section */
  text-align: left; /* Align the text to the left */
}

.starting-point-problem {
  
  margin-top: 50px;
  margin-bottom: 70px;
  width: 100%;
  align-self: center;
  text-align: center;
  background-color: #f1f0db9f; /* Background color for the tag */
  padding: 1% 10%; /* Adjust padding for the oval shape */
  border-radius: 40px; /* Create the oval shape */
  border: solid 1px rgb(167, 167, 167);
}

.starting-point-problem p{
  font-size: 22px;
}


.early-design-container{
  display: flex;
  flex-direction: column;
}

.early-design-graph-text{
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.early-design-text {
  width: 40%;
}

.simple-look-summary{
  margin-top: -5px;
  margin-bottom: 30px;
  max-width: 600px;
  
}

.simplified-image {
  width: 100%;
}

.development-container{
  margin-top: -10px;
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.movie-game-description {
  transition: opacity 0.6s ease, transform 0.6s ease, filter 0.6s ease;
}

.users-container{
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.dev-images{
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 600px;
}

.dev-image {
  max-height: 100%;
  max-width: 100%;
  width: auto;
}

.grid-container {
  display: grid;
  grid-template-columns: 0.8fr 0.8fr; /* Two equal columns */
  gap: 60px; /* Space between grid items */
  width: 100%; /* Full width container */
  margin: 0 auto; /* Center the container */
  
}

.grid-item {
  text-align: left; /* Align text to the left */
}




.movie-game-content {
padding: 2rem;
width: 90%;
max-width: 90%;
margin: 0 auto;
}

.movie-game-title {
  font-family: 'IS','Times New Roman', Times, serif;
  font-weight: 400;
  letter-spacing: -0.04em;
font-size: 8vw;
margin-top: 0; /* Remove top margin */
margin-bottom: 0.5rem;
text-align: center;
}

.movie-game-description {
font-size: 1.2em;
line-height: 1.6;
text-align: left;
color: #3C2A24;
}

.graph-container {
  margin: 20px 0;
  text-align: center;
}

.graph-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image-caption {
  margin-top: 8px;
  font-size: 0.9rem;
  color: #555; /* Adjust color for better readability */
  text-align: center;
  font-style: italic;
}


h2 {
  text-align: left; /* Align header to the left */
  font-size: 36px;
  font-weight: 600;
  font-family: 'ISI', 'Times New Roman', Times, serif;
  letter-spacing: 0.02em;
  margin-top: 40px; /* Larger top margin */
  margin-bottom: 20px; /* Bottom margin for spacing */
  color: #2a2a2a;
}

h3 {
  font-family: 'Helvetica';
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: -15px;
  color: #3a3a3a;
}

p {
  font-size: 18px;
  font-family: 'Helvetica';
  letter-spacing: -0.04em;
  line-height: 1.4; /* Lower line height for compact text */
  margin-bottom: 20px;
  color: #555;
}


ul {
  font-size: 18px; /* Match the font size of <p> */
  font-family: 'Helvetica';
  letter-spacing: -0.04em;
  line-height: 1.4; /* Match the line height of <p> */
  margin-bottom: 20px; /* Same spacing as <p> */
  color: #555; /* Match the color of <p> */
  list-style-type: disc; /* Default bullet style */
  padding-left: 20px; /* Add padding for indentation */
}

li {
  margin-bottom: 15px; /* Spacing between list items */
}

li strong {
  color: #3a3a3a; /* Keep bold text consistent with the text color */
}

.movie-game-description a {
color: #3C2A24;
text-decoration: underline; /* Optional, removes the underline */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  
  .movie-game-content {
    padding: 2rem;
    width: 99%;
    max-width: 99%;
    margin: 0 auto;
    
  }
  .movie-game-title {
    font-size: 11vw;
  }

   /* Responsive Project Info Section */
   .project-info-container {
    
    display: flex;
    flex-direction: column; /* Force column layout for mobile */
    flex-wrap: wrap; /* Allow wrapping if needed */
    justify-content: flex-start; /* Align items to the left */
    gap: 1rem; /* Add space between rows */
    width: 100%; /* Full width on mobile */
  }

  .project-detail {
    display: flex; /* Keep label and value inline */
    justify-content: flex-start; /* Align content to the left */
    align-items: center; /* Vertically align label and value */
    flex-basis: 100%; /* Make each item take the full width */
    margin-bottom: 1rem; /* Add space between rows */
    white-space: nowrap; /* Prevent wrapping of individual text unless needed */
  }

  .project-detail .label {
    font-weight: bold;
    margin-right: 0.5rem; /* Space between label and value */
  }
  .movie-game-description {
    font-size: 4vw;
    text-align: left;
  }

  .movie-image-container {
    width: 80vw;
  }

  .movie-game-description {
    font-size: 1em;
  }

  h2 {
    
    letter-spacing: -0.04em;
    line-height: 1.2;
    
  }

  .starting-point-container, .development-container{
    flex-direction: column;
  }

  .development-container{
    gap: 0px;
  }

  .starting-point-text {
    margin-top: -60px;
  }
  .starting-point-problem {
    width: 100%;
    margin-top: 20px;
  }

  .starting-point-problem p{
    font-size: 18px;
  }

  .early-design-graph-text{
    flex-direction: column;
  }
  .early-design-text {
    margin-bottom: -40px;
    width: 100%;
  }

  .users-container{
    flex-direction: column;
  }

  .grid-container {
    grid-template-columns: 1fr; /* Single column layout */
    gap: 0;
  }
}

