/* src/CaltrainCaseStudyPage.css */


  
  .caltrain-page {
    display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
  background-color: rgb(254, 255, 237);
  color: #3C2A24;
  font-family: "Helvetica Neue";
  letter-spacing: -0.03rem;
  padding-top: 0.5rem;
  position: relative; /* Add this line */
  padding-bottom: 0px;
  }
  .cal-content {
    padding: 2rem;
    width: 90%;
    
    max-width: 90%;
  
    margin: 0 auto;
  }
  
  


  
  


  .cal-title {
    font-size: 8vw;
  font-family: 'IS','Times New Roman', Times, serif;
  font-weight: 400;
  letter-spacing: -0.04em;
  margin-top: 0; /* Remove top margin */
  margin-bottom: 0.5rem;
  text-align: center;
  }
  
 
  
  h2 {
    font-size: 36px;
    font-weight: 500;
    font-family: 'ISI','Times New Roman', Times, serif;
    letter-spacing: -0.01em;
    margin-top: 40px; /* Larger top margin */
    margin-bottom: -10px; /* Bottom margin for spacing */
    color: #2a2a2a;
  }

  h3 {
    font-family: 'Helvetica';
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: -15px;
    color: #3a3a3a;
  }
  
  p {
    font-size: 18px;
    font-family: 'Helvetica';
    letter-spacing: -0.04em;
    line-height: 1.4; /* Lower line height for compact text */
    margin-bottom: 20px;
    color: #555;
  }
  
  ul {
    font-size: 18px; /* Match the font size of <p> */
    font-family: 'Helvetica';
    letter-spacing: -0.04em;
    line-height: 1.4; /* Match the line height of <p> */
    margin-bottom: 20px; /* Same spacing as <p> */
    color: #555; /* Match the color of <p> */
    list-style-type: disc; /* Default bullet style */
    padding-left: 20px; /* Add padding for indentation */
  }
  
  li {
    margin-bottom: 15px; /* Spacing between list items */
  }
  
  li strong {
    color: #3a3a3a; /* Keep bold text consistent with the text color */
  }

  .needfinding-container{
    display: flex;
    flex-direction: row;
    gap: 60px;
    margin-top: 20px;
  }

  .initial-images-container{
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .design-change-container{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 80px;
     /* Vertically center content within the container */
  }

  .design-change-container2{
    display: flex;
    flex-direction: row;
    align-items: center; /* Vertically center content within the container */
  }
  

  .highlighted-text {
    color:  rgb(150, 0, 0);
  }
  
  .persona-container {
    margin: 20px 0;
    text-align: center;
    
  }
  
  .design-image {
    max-width: 100%;
    height: auto;
    
  }

  .persona-image {
    width: 80%;
    
    height: auto;
    
  }

  .persona-class{
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .persona-info{
    flex-direction: column;
    width: 80%;
  }

  .design_video-container {
    margin: 20px auto; /* Center the container horizontally */
         /* Set a width for the container */
    max-height: 600px;
    display: flex;     /* Use flexbox for vertical alignment */
    justify-content: center; /* Center the video horizontally */
    border-radius: 60%;
}

.users-container{
  gap: 40px;
}

.design_video-container video {
  border-radius: 70px; /* Adjust the value for more or less rounding */
}
.design-change-text1{
  width: 60%;
}

.design-change-text{
  width: 40%;
}
.feasibility-container{
  width: 50%;
}

.feasibility-change-container{
  display: flex;
    flex-direction: row;
    margin-top: 30px;
}
  
  /* Responsive Design */
  @media (max-width: 768px) {
    h2 {
        letter-spacing: -0.02em;
        
    }
    .cal-content {
      padding: 2rem;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    }
    .persona-class{
      flex-direction: column;
    }
    .persona-container, .persona-info{
      width: 100%;
    }

    .persona-image{
      width: 100%;
    }

    .needfinding-container{
      flex-direction: column;
    }
    .initial-images-container{
      flex-direction: column;
    }

    .feasibility-change-container{
      flex-direction: column;
    }

    .design-change-text1, .feasibility-container, .design-change-text{
      width: 100%;
    }

    .design-change-container, .design-change-container2{
      flex-direction: column;
    }
    
  }
  