/* src/HostileArchitecturePage.css */
@font-face {
  font-family: "ISI";
  src: url("./assets/fonts/InstrumentSerif-Italic.ttf") format("truetype"); /* Update path to the correct font file */
  
}
@font-face {
  font-family: "IS";
  src: url("./assets/fonts/InstrumentSerif-Regular.ttf") format("truetype"); /* Update path to the correct font file */
  
}

.hostile-architecture-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: rgb(254, 255, 237);
    color: #3C2A24;
    font-family: "Helvetica Neue";
    letter-spacing: -0.02rem;
    padding-top: 5vh; 
    position: relative;
    
  }

  .hostile-architecture-content {
    padding: 2rem;
width: 90%;
max-width: 90%;
margin: 0 auto;
    
  }
  .hostile-architecture-title {
    font-size: 6vw;
    font-family: 'IS' ,'Times New Roman', Times, serif;
  font-weight: 400;
  letter-spacing: -0.04em;
    margin-top: 0; /* Remove top margin */
    margin-bottom: 0.5rem;
    text-align: center;
  }

  
  .project-description img {
    display: block; /* Ensures the image is on its own line */
    max-width: 100%; /* Makes the image responsive to screen size */
    min-width: 100%;
    height: auto; /* Maintains the aspect ratio */
    margin: 2rem auto; /* Adds some spacing around the image and centers it */
    border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
     /* Optional: Rounds the corners slightly */
    /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for better visuals */
  }

  figure {
    margin: 1rem auto;
    text-align: center; /* Centers the caption below the image */
  }
  
  figcaption {
    margin-top: -1.5rem; /* Adds spacing between the image and the caption */
    font-size: 0.9rem;
  color: #555; /* Adjust color for better readability */
  text-align: center;
  font-style: italic;
  }
  
  
  
  .hostile-architecture-page .media-container {
    position: relative;
    width: 90%;
    max-width: 90%;
    margin: 30px auto;
  }
  
  .hostile-architecture-page .work-imageh,
  .hostile-architecture-page .work-video {
    width: 100%;
    height: auto;
    border-radius: 0px;
  }
  
  .hostile-architecture-page .work-video {
    aspect-ratio: 16 / 9;
  }
  
  .hostile-architecture-page .toggle-media-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    color: white;
    background-color: rgba(255, 0, 0, 0.7);
    
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-family: "Helvetica Neue";
    font-weight: 700;
  }
  
  .hostile-architecture-page a {
    
    text-decoration: none;
  }
  
  .hostile-architecture-page .embed-container {
    margin: 40px auto;
    width: 90%;
    
    height: 800px;
    /*border: 1px solid white;*/
    border-radius: 0px;
    overflow: hidden;
  }
  
  .hostile-architecture-page .embed-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  
  .project-info-container {
    width: 60%; /* Set a maximum width for the container */
    margin: 0 auto; /* Center the container horizontally */
  }
  
  .project-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  
  .project-detail {
    flex-basis: 45%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .project-detail .label {
    font-weight: bold;
    margin-right: 0.5rem;
  }

  .divider {
    border: none;
    border-top: 1px solid #ccc;
  }

  .project-description {
    font-size: 1.2em;
    line-height: 1.6;
    text-align: left;
  }
  
  .insight-image-text{
    display: flex;
    flex-direction: row;
    gap: 60px;
  }

  .insight-image{
    min-width: 60%;
  }

  .highligh-learning-container{
    display: flex;
    flex-direction: row;
    gap: 60px;
  }
  
@media (max-width: 768px) {
.hostile-architecture-content{
  padding: 2rem;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    word-wrap: break-word;
  }
  .hostile-architecture-page {
   text-align: center;
  }

  .hostile-architecture-title {
    
    font-size: 9.5vw;
    margin-top: -4vh; /* Remove top margin */
    
  }
  
 .hostile-architecture-page .embed-container {
    
    width: 80%;
    height: 50vh;
    
  }
  .hostile-architecture-page .media-container {
    position: relative;
    width: 80%;
    max-width: 80%;
    margin: 30px auto;
  }

  .insight-image-text{
    flex-direction: column;
  }

  .highligh-learning-container{
    flex-direction: column;
  }


}