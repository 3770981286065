/* src/components/ProjectNavigator.css */

.project-navigator {
    
    margin-top: 15rem;
    padding: 0 1rem; /* Adjust horizontal padding for alignment */
    text-align: center; /* left */
    width: 100%;
  }
  
  .project-navigator h3 {
    font-family: 'Helvetica';
    font-weight: 400;
    font-size: 4rem;
    letter-spacing: -0.05em;
    margin-bottom: 1.3rem;
    color: #333;
    padding-left: 1rem; /* Add left padding for alignment with the content */
  }
  
  /* Apply box-sizing globally */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  /* Divider Styling */
  .project-navigator-divider {
    border: none;
    border-top: 1px solid #ccc;
    margin: 1rem 0;
    width: calc(100% - 2rem); /* Align to padding in the parent container */
    margin-left: auto; /* Ensure divider aligns with content */
    margin-right: auto; /* Center horizontally */
  }
 
  /* Project List */
  .project-navigator-list {
    display: flex;
    flex-direction: column; /* Stack each project row vertically */
    width: 100%;
    padding: 0; /* Remove default padding */
    margin: 0; /* Ensure no extra horizontal margins */
  }
  
  /* Project Item */
  .project-navigator-item {
    display: flex;
    width: 100%;
    flex-direction: row; /* Align image and title side by side */
    align-items: center; /* Center image and text vertically */
    text-align: left; /* Align text to the left */
    gap: 1rem; /* Add spacing between the image and the title */
    padding-left: 1rem; /* Align with the heading */
  }
  
  /* Link Styling */
  .project-navigator-link {
    width: 98.8%;
    display: flex;
    align-items: center;
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Inherit text color */
    transition: box-shadow 0.5s ease, transform 0.2s; /* Add hover effect */
  }
  
  /* Hover Effect */
  .project-navigator-link:hover {
    /*transform: scale(1.02); /* Slight zoom on hover */
    box-shadow: 0 0 0 2px rgba(150, 0, 0, 1);
  }
  
  /* Image Styling */
  .project-navigator-image {
    width: 200px; /* Fixed width for the image */
    height: auto; /* Maintain aspect ratio */
    
  }
  
  /* Title Styling */
  .project-navigator-title {
    font-family: 'ISI', Times, serif;
    font-weight: 100;
    font-size: 3rem;
    padding-left: 1.5rem;
    color: #555;
    margin: 0;
  }

  @font-face {
    font-family: "ISI";
    src: url("../assets/fonts/InstrumentSerif-Italic.ttf") format("truetype"); /* Update path to the correct font file */
    
  }
  
  @media (max-width: 768px) {
    .project-navigator {
    
        margin-top: 5rem;
    
      }
    .project-navigator h3 {
        
        font-size: 2rem;
        letter-spacing: -0.05em;
        
      }
    .project-navigator-title {
        
        font-size: 1.5rem;
        
      }

      .project-navigator-image {
        width: 120px; /* Fixed width for the image */
        height: auto; /* Maintain aspect ratio */
        
      }
  }