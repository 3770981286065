.prescription-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: black;
    color: white;
    font-family: 'Arial', sans-serif;
    padding-top: 5vh; 
    position: relative;
    overflow: hidden;
}

.prescription-title {
    font-size: 4.5vw;
    margin-top: 0;
    margin-bottom: 0.5rem;
    text-align: center;
    z-index: 10;
}

.image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.prescription-image {
    position: absolute;
    transition: transform 0.5s ease;
    cursor: pointer;
}

.nice-text {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    color: white;
    opacity: 0;
    animation: fadeInOut 2s ease-in-out;
    z-index: 1000;
}

@keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}