.about-page-header2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; /* Ensures the header spans the width of the viewport */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px; /* Adjust padding for spacing */
    z-index: 1000; /* Ensures it stays on top of other content */
    mix-blend-mode: difference; /* Applies blending mode to the entire header */
  }
  
  .invert-text {
    color: white; /* Base color */
    mix-blend-mode: difference; /* Inverts color based on background */
    
    text-decoration: none; /* Remove underline for links */
  }
  
  .about-page-link,
  .nav-link {
    transition: color 0.3s ease; /* Optional: smooth transition for color changes */
  }
  
  
  