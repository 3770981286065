/* src/EclipsePage.css */
@font-face {
  font-family: "ISI";
  src: url("./assets/fonts/InstrumentSerif-Italic.ttf") format("truetype"); /* Update path to the correct font file */
  
}
@font-face {
  font-family: "IS";
  src: url("./assets/fonts/InstrumentSerif-Regular.ttf") format("truetype"); /* Update path to the correct font file */
  
}

.eclipse-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: auto;
    background-color: rgb(254, 255, 237);
    color: #3C2A24;
    font-family: "Helvetica Neue";
    letter-spacing: -0.03rem;
    padding-top: 0.5rem; 
    position: relative; /* Add this line */
  }
  
  .gif-container {
    width: 90%;
    margin: 0 auto 2rem auto;
    
    /*border-radius: 20px;*/
    overflow: hidden;
  }
  
  .eclipse-gif {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .eclipse-content {
    padding: 2rem;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 10vh; 
  }
  
  .eclipse-title {
    font-size: 8vw;
    font-family: 'IS', 'Times New Roman', Times, serif;
  font-weight: 400;
  letter-spacing: -0.04em;
    margin-top: 0; /* Remove top margin */
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .eclipse-description {
    font-size: 1.2em;
    line-height: 1.6;
    text-align: justify;
  }

 
  .project-detail {
    display: flex;
    flex-direction: row; /* Keep the label and value in one line */
    align-items: center;
    white-space: nowrap; /* Prevent text from wrapping inside the detail */
  }
  
  .project-detail .label {
    font-weight: bold;
    margin-right: 0.5rem;
  }
  
  .innovators-container{
    display: flex;
    flex-direction: row;
    gap: 60px;
    
  }

  .innovator-problem{
    margin-top: 10px;
    width: 50%;
  }
 
  .branding-images-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 3 columns */
    gap: 20px; /* Space between the grid items */
    padding: 20px; /* Optional padding around the grid */
  }
  
  .branding-image-item {
    display: flex; /* Ensures content inside is centered if needed */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  .branding-image {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
 
  .figma-link {
    color: #555;
    text-decoration: underline;
  }
  
  .figma-link:hover {
    color: #0056b3; /* Hover effect */
  }
  

  
 
  
  .poster-image {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .poster-caption {
    margin-top: -1.5rem;
    font-size: 1rem;
    color: #555; /* Adjust to match your color scheme */
  }
  
  .homepage-container {
    display: flex; /* Align text and images in a row */
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    gap: 60px; /* Space between text and images */
    
  }
  
  .simple-look-summary3 {
    flex: 1; /* Allow the text section to take up available space */
    max-width: 400px; /* Optional: limit the width of the text */
  }
  
  .homepage-images-container {
    display: flex; /* Align images side by side */
    flex: 2; /* Allow the images section to grow */
    gap: 60px; /* Space between images */
    align-items: center; /* Vertically align the images */
  }
  
  .homepage-image-item {
  
    display: flex;
    flex-direction: column; /* Stack image and caption vertically */
    align-items: center;
    text-align: center;
  }
  
  .homepage-image {
    max-height: 600px; /* Set a fixed height */
    width: auto; /* Allow width to adjust automatically */
    object-fit: cover; /* Maintain aspect ratio and fill the set height */
    border-radius: 8px; /* Optional: add rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: add shadow */
  }
  
  .homepage-caption {
    margin-top: 10px; /* Space between image and caption */
    font-size: 14px; /* Adjust caption size */
    color: #555; /* Optional: adjust caption color */
  }
  

.poster-images-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default: 3 columns */
  grid-template-rows: auto auto; /* Two rows: one for the first three, one for the last */
  gap: 80px; /* Space between items */
  padding: 20px; /* Optional padding around the container */
  
}

.poster-image-item:nth-child(4) {
  grid-column: span 3; /* The fourth item spans all 3 columns */
   /* Center-align the content if needed */
}

  .divider {
    border: none;
    border-top: 1px solid #ccc;
    margin: 2rem 0;
  }

  /* individual images and texts*/
  .poster-title {
    font-size: 4vw;
  }

 .image-text-container {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal columns for image1 and image2 */
  grid-template-rows: auto auto; /* One row for images, one row for image3 */
  grid-gap: 2rem; /* Adds spacing between images */
  margin-bottom: 4rem;
}

/* Styles for image1 and image2 */
.image-1, .image-2 {
  width: 90%; /* Make sure images fill their grid columns */
  height: auto;
}

/* Positioning image3 below image1 and image2 */
.image-3 {
  grid-column: span 2; /* Spans both columns */
  width: 197%;
  height: auto;
}

/* Text styling for images */
.section-text {
  text-align: left;
  margin-top: 1rem;
}

.eclipse-reflection-container{
  display: flex;
  flex-direction: row;
  gap: 60px;
}
  
  @media (max-width: 768px) {

  /* General Content Adjustments */
  .eclipse-description {
    text-align: left;
    font-size: 4vw;
    line-height: 1.5;
    text-align: justify;
  }
  
  .eclipse-title {
    font-size: 12vw;
  }

  .eclipse-content {
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 5vh;
  }

  /* GIF Container Adjustments */
  .gif-container {
    width: 90vw;
    overflow: hidden;
  }

  /* Responsive Project Info Section */
  .project-info-container {
    display: flex;
    flex-direction: column; /* Force column layout for mobile */
    flex-wrap: wrap; /* Allow wrapping if needed */
    justify-content: flex-start; /* Align items to the left */
    gap: 1rem; /* Add space between rows */
    width: 100%; /* Full width on mobile */
  }

  .project-detail {
    display: flex; /* Keep label and value inline */
    justify-content: flex-start; /* Align content to the left */
    align-items: center; /* Vertically align label and value */
    flex-basis: 100%; /* Make each item take the full width */
    margin-bottom: 1rem; /* Add space between rows */
    white-space: nowrap; /* Prevent wrapping of individual text unless needed */
  }

  .project-detail .label {
    font-weight: bold;
    margin-right: 0.5rem; /* Space between label and value */
  }

  /* Responsive Poster Titles */
  .poster-title {
    font-size: 10vw;
  }
  .poster-images-container {
    grid-template-columns: 1fr; /* Single column for mobile */
    grid-template-rows: none; /* Let the rows flow naturally */
  }

  .poster-image-item:nth-child(4) {
    grid-column: span 1; /* Reset grid span for single column */
  }

  .poster-image {
    max-width: 100%; /* Allow images to span full width in single column */
  }

  /* Responsive Image/Text Layout */
  .image-text-container {
    display: grid;
    grid-template-columns: 1fr; /* Stack images vertically on small screens */
    grid-template-rows: auto auto auto; /* Create rows for each image */
    gap: 1rem; /* Add space between images */
  }

  .image-1, .image-2, .image-3 {
    width: 100%; /* Make images take full width */
  }
  .branding-images-container {
    grid-template-columns: 1fr; /* Single column for mobile */
    gap: 0px; /* Smaller gap for mobile */
  }

  .innovators-container, .eclipse-reflection-container{
    flex-direction: column;
  }

  .eclipse-reflection-container{
    gap: 0px;
  }

  .innovator-problem{
    margin-top: 10px;
    width: 100%;
  }
  .homepage-container {
    flex-direction: column; /* Stack text and images vertically */
    gap: 30px; /* Increase spacing between sections */
  }

  .simple-look-summary3, .homepage-images-container {
    max-width: 100%; /* Allow both sections to use full width */
    flex: unset; /* Reset flex sizing */
  }

  .homepage-images-container {
    flex-direction: column; /* Stack images vertically */
    gap: 20px; /* Space between images */
  }

  .homepage-image {
    height: auto; /* Allow height to adjust for smaller screens */
    max-width: 100%; /* Ensure images fit within the container */
  }
}
