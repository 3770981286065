/* src/JFFPage.css */
.jff-page {
    min-height: 100vh;
    background-color: black;
    color: white;
    font-family: "Helvetica Neue";
    padding: 2vh 5vw;
    display: flex;
    flex-direction: column;
}

.jff-title {
    font-size: 7vw;
    margin-bottom: 2rem;
    margin-top: 0; /* Remove top margin */
    text-align: center;
}


.jff-image-container {
    position: relative;
    width: 100%;
    height: 80vh;
}

.jff-svg-link {
    display: inline-block;
    transition: transform 0.3s ease;
}

.jff-svg-link:hover {
    transform: scale(1.02);
    z-index: 1;
}

.jff-svg {
    width: 100%;
    height: auto;
}

.jff-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.jff-svg-link:hover .jff-overlay {
    opacity: 1;
}

.jff-idea-name {
    color:rgb(254, 255, 237);
    font-weight: 500;
    letter-spacing: -0.02rem;
}