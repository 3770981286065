/* src/components/BackButton.css */
.back-button {
  position: fixed;
  top: 7vh;
  left: 2vw;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.3s;
}

.back-button:hover {
  transform: scale(1.1);
}

.back-arrow-icon {
  width: 1.8vw; /* Adjust this value to set the size of your arrow icon */
  /*height: auto;*/
}

/* Media Queries for Responsive Design */

@media (max-width: 768px) {
  .back-button {
    position: fixed;
    top: 3vh;
    left: 2vw;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s;
  }
  .back-arrow-icon {
    width: 4vw; /* Adjust this value to set the size of your arrow icon */
    /*height: auto;*/
  }
}